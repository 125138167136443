<template>
<v-card elevation="1"
				class="mx-auto my-5"
				style="max-width:1280px;">
	<v-tabs v-model="tab"
					color="deep-purple accent-4"
					left>
		<v-tab>Les propriétés</v-tab>
		<v-tab>France</v-tab>
		<v-tab>Allemagne</v-tab>
		<v-tab-item>

			<div class="row gy-6">
				<div class="col-lg-4 col-md-6 col-sm-6"
						 v-for="(item, index) in data_list">
					<div class="mx-3">
						<div class="card"
								 @click="table_id=item.id; tab= 1;">
							<div class="card-body">
								<h5 class="card-title"> {{item.code_postal}} {{item.ville}} </h5>
								<img :src="'https://media.berlcoin.fr/'+item.ville.toLowerCase()+'.jpg'"
										 style="width:100%" />
								<p class="card-text"><br />

									{{item.adresse}}
									<b style="float:right;text-align:right;"
										 class="text-success"
										 v-html="monetary_aspect(item.anticipation)+' %'">
									</b>
								</p>
							</div>
							<!--/.card-footer -->
						</div>
						<!--/.row -->
					</div>
					<!--/column -->
				</div>
			</div>



			<v-data-table item-key="id"
										hide-default-footer
										:headers="headers"
										:items="data_list"
										:search="search">
				<template v-slot:item.id="{ item }">
					<v-radio-group v-model="table_id">
						<v-radio :value="item.id"></v-radio>
					</v-radio-group>
				</template>

				<template v-slot:item.total="{ item }">
					<div v-html="monetary_aspect( item.total)"></div>
				</template>

				<template v-slot:item.nb_token="{ item }">
					<div v-html="monetary_aspect( item.nb_token)"></div>
				</template>


			</v-data-table>
			<div class="alert alert-success alert-icon text-center"
					 role="alert">
				<a href="https://berlcoin.berlin/fr/invest"
					 class="alert-link hover">
					Investir à Berlin avec le Berlcoin : 11.5% en 2021 </a>
			</div>

			<div class="row gy-6">
				<div class="col-lg-4 col-md-6  col-sm-6"
						 v-for="(item, indexb) in berlcoin_property">
					<div class="mx-3">
						<div class="card"
								 @click="berlcoin_id=item.id; tab= 2;">
							<div class="card-body">
								<h5 class="card-title"> {{item.name}} {{item.ville}} </h5>
								<img :src="'https://media.berlcoin.fr/property/'+item.path+'/1.jpg'"
										 style="width:100%" />
								<p class="card-text"><br />

									<span class="text-secondary"> {{item.address}} </span>
									<b style="float:right;text-align:right;"
										 class="text-success">
										{{ ( parseFloat(item.yield) + parseFloat(item.capital_gain)  )  }} %</b>
								</p>
							</div>
							<!--/.card-footer -->
						</div>
						<!--/.row -->
					</div>
					<!--/column -->
				</div>
			</div>

			<v-card-title>
				<v-text-field v-model="search"
											v-if="false"
											append-icon="mdi-magnify"
											label="Recherche"
											single-line
											hide-details></v-text-field>
			</v-card-title>


			<v-data-table item-key="id"
										hide-default-footer
										:headers="headers_berlcoin"
										:items="berlcoin_property"
										:search="search">
				<template v-slot:item.id="{ item }">
					<v-radio-group v-model="berlcoin_id">
						<v-radio :value="item.id"></v-radio>
					</v-radio-group>
				</template>


				<template v-slot:item.euros="{ item }">
					<div v-html="monetary_aspect( item.euros)"></div>
				</template>

				<template v-slot:item.berlcoin="{ item }">
					<div v-html="monetary_aspect( item.berlcoin)"></div>
				</template>

			</v-data-table>




		</v-tab-item>
		<v-tab-item>


			<template>

				<!--/.row -->
				<div class="row mb-6">
					<div class="col-lg-12">


						<div class="card-body">
							<a style="background-color:green;float:right;color:white"
								 :href="'souscrire/'+ detail.token_asset_code"
								 v-if="detail.solde>0"
								 class="btn  ">Souscrire pour {{detail.ville}}</a>

							<a style="float:right"
								 v-if="detail.solde==0"
								 class="btn btn-primary p-1 px-3">Plus de fraction disponible</a>

							<h3 class="ml-10"
									v-html="detail.ville+' : '+detail.adresse" />


							<h4 class="my-6 text-red"> Présentation </h4>

							<textarea v-model="detail.offre"
												v-if="style_aff=='form'"
												style="width:100%"
												rows=10> {{detail.offre}} </textarea>

							<span class="lead mb-3"
										v-if="style_aff=='present' & detail.offre != undefined"
										v-html="detail.offre.replaceAll('\n','<br/>')" />

							<div v-if="style_aff=='form'">
								<h4 class="my-6 text-red"> Localisation </h4>
								<v-row v-if="false">
									<v-col cols="6">
										Categorie "
										<v-text-field v-if="style_aff=='form'"
																	v-model="detail.categorie"
																	counter
																	maxlength="199"></v-text-field>
									</v-col>

								</v-row>
								Adresse <b style="width:100%;text-align:right;"
									 v-if="style_aff=='present'"
									 class="text-success"
									 v-html=" (detail.adresse)" />

								<v-text-field v-if="style_aff=='form'"
															v-model="detail.adresse"
															counter
															maxlength="199"></v-text-field>
								<v-row>

									<v-col cols="4">
										Code postal <b style="width:100%;text-align:right;"
											 v-if="style_aff=='present'"
											 class="text-success"
											 v-html=" (detail.code_postal)" />

										<v-text-field v-if="style_aff=='form'"
																	v-model="detail.code_postal"
																	counter
																	maxlength="199"></v-text-field>
									</v-col>


									<v-col cols="8">
										Ville <b style="width:100%;text-align:right;"
											 v-if="style_aff=='present'"
											 class="text-success"
											 v-html=" (detail.ville)" />

										<v-text-field v-if="style_aff=='form'"
																	v-model="detail.ville"
																	counter
																	maxlength="199"></v-text-field>
									</v-col>
								</v-row>
							</div>



							<h4 class="my-6 text-red"> Foncier </h4>
							<div class="row row-cols-2 row-cols-md-3 row-cols-xl-5 gx-lg-6 gy-6 justify-content-left">

								<div class="col">
									<div class="card m-1 p-2">


										Section cadastrale
										<b style="width:100%;text-align:right;"
											 v-if="style_aff=='present'"
											 class="text-success"
											 v-html=" (detail.section_cadastrale)" />

										<v-text-field v-if="style_aff=='form'"
																	v-model="detail.section_cadastrale"
																	counter
																	maxlength="199"></v-text-field>

									</div>
								</div>

								<div class="col">
									<div class="card m-1 p-2">
										Parcelle
										<b style="width:100%;text-align:right;"
											 v-if="style_aff=='present'"
											 class="text-success"
											 v-html=" (detail.parcelle)" />

										<v-text-field v-if="style_aff=='form'"
																	v-model="detail.parcelle"
																	counter
																	maxlength="199"></v-text-field>
									</div>
								</div>

								<div class="col">
									<div class="card m-0 p-3">
										Surface de la parcelle
										<b style="width:100%;text-align:right;"
											 v-if="style_aff=='present'"
											 class="text-success"
											 v-html="monetary_aspect(detail.surface_terrain)" />

										<v-text-field v-if="style_aff=='form'"
																	v-model="detail.surface_terrain"
																	counter
																	maxlength="199"></v-text-field>
									</div>
								</div>

								<div class="col">
									<div class="card m-0 p-2"
											 style="height:100%">
										Surface non construite
										<b style="width:100%;text-align:right;"
											 v-if="style_aff=='present'"
											 class="text-success"
											 v-html="monetary_aspect(detail.surface_constructible)" />

										<v-text-field v-if="style_aff=='form'"
																	v-model="detail.surface_constructible"
																	counter
																	maxlength="199"></v-text-field>
									</div>
								</div>



							</div>
							<h4 class="my-6 text-red">Bâtiment </h4>
							<div class="row row-cols-2 row-cols-md-3 row-cols-xl-5 gx-lg-6 gy-6 justify-content-left">

								<div class="col">
									<div class="card m-0 p-2"
											 style="height:100%">
										Construction <b style="width:100%;text-align:right;"
											 v-if="style_aff=='present'"
											 class="text-success"
											 v-html=" (detail.annee_construction)" />
										<v-text-field v-if="style_aff=='form'"
																	v-model="detail.annee_construction"
																	counter
																	maxlength="199"></v-text-field>
									</div>
								</div>

								<div class="col">
									<div class="card m-1 p-2">
										Surface habitable <b style="width:100%;text-align:right;"
											 v-if="style_aff=='present'"
											 class="text-success"
											 v-html="monetary_aspect(detail.surface_habitable)" />
										<v-text-field v-if="style_aff=='form'"
																	v-model="detail.surface_habitable"
																	counter
																	maxlength="199"></v-text-field>
									</div>
								</div>

								<div class="col">
									<div class="card m-0 p-2"
											 style="height:100%">
										Surface aménageable <b style="width:100%;text-align:right;"
											 v-if="style_aff=='present'"
											 class="text-success"
											 v-html="monetary_aspect(detail.surface_amenagable)" />
										<v-text-field v-if="style_aff=='form'"
																	v-model="detail.surface_amenagable"
																	counter
																	maxlength="199"></v-text-field>
									</div>
								</div>

								<div class="col">
									<div class="card m-0 p-2"
											 style="height:100%">
										Surface sous sol

										<b style="width:100%;text-align:right;"
											 v-if="style_aff=='present'"
											 class="text-success"
											 v-html="monetary_aspect(detail.surface_sous_sol)" />

										<v-text-field v-if="style_aff=='form'"
																	v-model="detail.surface_sous_sol"
																	counter
																	maxlength="199"></v-text-field>
									</div>
								</div>


								<div class="col">
									<div class="card m-1 p-2">
										Niveau <b style="width:100%;text-align:right;"
											 v-if="style_aff=='present'"
											 class="text-success"
											 v-html="monetary_aspect(detail.nb_niveau)" />
										<v-text-field v-if="style_aff=='form'"
																	v-model="detail.nb_niveau"
																	counter
																	maxlength="199"></v-text-field>
									</div>
								</div>

								<div class="col"
										 v-if="false">
									<div class="card m-0 p-2"
											 style="height:100%">
										Murs mitoyens <b style="width:100%;text-align:right;"
											 v-if="style_aff=='present'"
											 class="text-success"
											 v-html="monetary_aspect(detail.mur_mitoyen)" />
										<v-text-field v-if="style_aff=='form'"
																	v-model="detail.mur_mitoyen"
																	counter
																	maxlength="199"></v-text-field>
									</div>
								</div>
							</div>
							<h4 class="my-6 text-red"> Eléments du bâtiment </h4>
							<div class="row row-cols-2 row-cols-md-3 row-cols-xl-5 gx-lg-6 gy-6 justify-content-left">

								<div class="col">
									<div class="card m-1 p-2">
										Pièces
										<b style="width:100%;text-align:right;"
											 v-if="style_aff=='present'"
											 class="text-success"
											 v-html="monetary_aspect(detail.nb_piece)" />
										<v-text-field v-if="style_aff=='form'"
																	v-model="detail.nb_piece"
																	counter
																	maxlength="199"></v-text-field>
									</div>
								</div>


								<div class="col">
									<div class="card m-1 p-2">
										Salle de bain
										<b style="width:100%;text-align:right;"
											 v-if="style_aff=='present'"
											 class="text-success"
											 v-html="monetary_aspect(detail.nb_salle_bain)" />
										<v-text-field v-if="style_aff=='form'"
																	v-model="detail.nb_salle_bain"
																	counter
																	maxlength="199"></v-text-field>
									</div>
								</div>

								<div class="col">
									<div class="card m-0 p-2"
											 style="height:100%">
										Parking
										<b style="width:100%;text-align:right;"
											 v-if="style_aff=='present'"
											 class="text-success"
											 v-html="monetary_aspect(detail.places_parking)" />
										<v-text-field v-if="style_aff=='form'"
																	v-model="detail.places_parking"
																	counter
																	maxlength="199"></v-text-field>
									</div>
								</div>

								<div class="col">
									<div class="card m-0 p-2"
											 style="height:100%">
										Annexes
										<b style="width:100%;text-align:right;"
											 v-if="style_aff=='present'"
											 class="text-success"
											 v-html=" (detail.batiment_annexes)" />
										<v-text-field v-if="style_aff=='form'"
																	v-model="detail.batiment_annexes"
																	counter
																	maxlength="199"></v-text-field>
									</div>
								</div>
								<div class="col">
									<div class="card m-0 p-2"
											 style="height:100%">
										Piscine
										<b style="width:100%;text-align:right;"
											 v-if="style_aff=='present'"
											 class="text-success"
											 v-html=" (detail.piscine)" />
										<v-text-field v-if="style_aff=='form'"
																	v-model="detail.piscine"
																	counter
																	maxlength="199"></v-text-field>
									</div>
								</div>



								<div class="col">
									<div class="card m-0 p-2"
											 style="height:100%">
										Tout à l'égout
										<b style="width:100%;text-align:right;"
											 v-if="style_aff=='present'"
											 class="text-success"
											 v-html=" (detail.racccordement_eau_usees)" />
										<v-text-field v-if="style_aff=='form'"
																	v-model="detail.racccordement_eau_usees"
																	counter
																	maxlength="199"></v-text-field>
									</div>
								</div>



							</div>


							<h4 class="my-6 text-red">Marché local </h4>
							<div class="row row-cols-2 row-cols-md-3 row-cols-xl-5 gx-lg-6 gy-6 justify-content-left">
								<div class="col">
									<div class="card m-0 p-2"
											 style="height:100%">
										Croissance annuelle

										<b style="width:100%;text-align:right;"
											 v-if="style_aff=='present'"
											 class="text-success"
											 v-html="monetary_aspect(detail.taux_1an)+' %'" />


										<v-text-field v-if="style_aff=='form'"
																	v-model="detail.taux_1an"
																	counter
																	maxlength="199"></v-text-field>
									</div>
								</div>
								<div class="col">
									<div class="card m-0 p-2"
											 style="height:100%">
										Croissance 5 ans
										<b style="width:100%;text-align:right;"
											 v-if="style_aff=='present'"
											 class="text-success"
											 v-html="monetary_aspect(detail.taux_5an)+' %'" />

										<v-text-field v-if="style_aff=='form'"
																	v-model="detail.taux_5an"
																	counter
																	maxlength="199"></v-text-field>
									</div>
								</div>
								<div class="col">
									<div class="card m-0 p-2"
											 style="height:100%">
										<div class="caption"> Croissance annualisé 5 ans</div>
										<b style="width:100%;text-align:right;"
											 class="text-success"
											 v-html="monetary_aspect(croissance_annualise)+' %'" />


									</div>
								</div>

								<div class="col">
									<div class="card m-0 p-2"
											 style="height:100%">
										Tension Immobilière
										<b style="width:100%;text-align:right;"
											 v-if="style_aff=='present'"
											 class="text-success"
											 v-html="monetary_aspect(detail.iti)" />

										<v-text-field v-if="style_aff=='form'"
																	v-model="detail.iti"
																	counter
																	maxlength="199"></v-text-field>
									</div>
								</div>
							</div>


							<h4 class="my-6 text-red">Données financières investissement </h4>
							<div class="row row-cols-2 row-cols-md-3 row-cols-xl-5 gx-lg-6 gy-6 justify-content-left">

								<div class="col">
									<div class="card m-0 p-2"
											 style="height:100%">
										Date investissement
										<b style="width:100%;text-align:right;"
											 v-if="style_aff=='present'"
											 class="text-success"
											 v-html=" (detail.date_investissement)" />


										<v-text-field v-if="style_aff=='form'"
																	v-model="detail.date_investissement"
																	counter
																	maxlength="199"></v-text-field>
									</div>
								</div>

								<div class="col">
									<div class="card m-0 p-2"
											 style="height:100%">
										Coût d'acquisition
										<b style="width:100%;text-align:right;"
											 v-if="style_aff=='present'"
											 class="text-success"
											 v-html="monetary_aspect(detail.prix_achat)" />


										<v-text-field v-if="style_aff=='form'"
																	v-on:keyup="mise_a_jour()"
																	v-model="detail.prix_achat"
																	counter
																	maxlength="199"></v-text-field>
									</div>
								</div>
								<div class="col">
									<div class="card m-0 p-2"
											 style="height:100%">
										Travaux
										<b style="width:100%;text-align:right;"
											 v-if="style_aff=='present'"
											 class="text-success"
											 v-html="monetary_aspect(detail.travaux)" />


										<v-text-field v-if="style_aff=='form'"
																	v-on:keyup="mise_a_jour()"
																	v-model="detail.travaux"
																	counter
																	maxlength="199"></v-text-field>
									</div>
								</div>
								<div class="col">
									<div class="card m-0 p-2"
											 style="height:100%">
										Mobilier
										<b style="width:100%;text-align:right;"
											 v-if="style_aff=='present'"
											 class="text-success"
											 v-html="monetary_aspect(detail.mobilier)" />


										<v-text-field v-if="style_aff=='form'"
																	v-on:keyup="mise_a_jour()"
																	v-model="detail.mobilier"
																	counter
																	maxlength="199"></v-text-field>
									</div>
								</div>

								<div class="col">
									<div class="card m-0 p-2"
											 style="height:100%">
										Réserves
										<b style="width:100%;text-align:right;"
											 v-if="style_aff=='present'"
											 class="text-success"
											 v-html="monetary_aspect(detail.reserve)" />


										<v-text-field v-if="style_aff=='form'"
																	v-on:keyup="mise_a_jour()"
																	v-model="detail.reserve"
																	counter
																	maxlength="199"></v-text-field>
									</div>
								</div>

							</div>

							<h4 class="my-6 text-red">Rendement </h4>
							<div class="row row-cols-2 row-cols-md-3 row-cols-xl-5 gx-lg-6 gy-6 justify-content-left">

								<div class="col">
									<div class="card m-0 p-2"
											 style="height:100%">
										Nombre de chambre
										<b style="width:100%;text-align:right;"
											 v-if="style_aff=='present'"
											 class="text-success"
											 v-html="monetary_aspect(detail.nb_chambre)" />


										<v-text-field v-if="style_aff=='form'"
																	v-on:keyup="mise_a_jour()"
																	v-model="detail.nb_chambre"
																	counter
																	maxlength="199"></v-text-field>
									</div>
								</div>

								<div class="col">
									<div class="card m-0 p-2"
											 style="height:100%">
										Location / chambre
										<b style="width:100%;text-align:right;"
											 v-if="style_aff=='present'"
											 class="text-success"
											 v-html="monetary_aspect(detail.location_chambre)" />


										<v-text-field v-if="style_aff=='form'"
																	v-on:keyup="mise_a_jour()"
																	v-model="detail.location_chambre"
																	counter
																	maxlength="199"></v-text-field>
									</div>
								</div>

								<div class="col">
									<div class="card m-0 p-2"
											 style="height:100%">
										% : Charge & entretien
										<b style="width:100%;text-align:right;"
											 v-if="style_aff=='present'"
											 class="text-success"
											 v-html="monetary_aspect(detail.charge)" />


										<v-text-field v-if="style_aff=='form'"
																	v-on:keyup="mise_a_jour()"
																	v-model="detail.charge"
																	counter
																	maxlength="199"></v-text-field>
									</div>
								</div>

								<div class="col">
									<div class="card m-0 p-2"
											 style="height:100%">
										Plus value latente
										<b style="width:100%;text-align:right;"
											 v-if="style_aff=='present'"
											 class="text-success"
											 v-html="monetary_aspect(detail.plus_value_latente)" />


										<v-text-field v-if="style_aff=='form'"
																	v-on:keyup="mise_a_jour()"
																	v-model="detail.plus_value_latente"
																	counter
																	maxlength="199"></v-text-field>
									</div>
								</div>

								<div class="col">
									<div class="card m-0 p-2"
											 style="height:100%">
										% : intérêt composés

										<b style="width:100%;text-align:right;"
											 v-if="style_aff=='present'"
											 class="text-success"
											 v-html="monetary_aspect(detail.interetcompose)" />

										<v-text-field v-if="style_aff=='form'"
																	v-on:keyup="mise_a_jour()"
																	v-model="detail.interetcompose"
																	counter
																	maxlength="199"></v-text-field>
									</div>
								</div>

							</div>
							<hr style="width:100%; border:1px dotted green;"
									class="my-7" />
							<div class="row row-cols-2 row-cols-md-3 row-cols-xl-5 gx-lg-6 gy-6 justify-content-left">

								<div class="col">
									<div class="card m-0 p-2"
											 style="height:100%">
										Projet <br />

										<b style="width:100%;text-align:right;"
											 class="text-success"
											 v-html="monetary_aspect(total_projet)" />


									</div>
								</div>


								<div class="col">
									<div class="card m-0 p-2"
											 style="height:100%">
										Location / mois

										<b style="width:100%;text-align:right;"
											 class="text-success"
											 v-html="monetary_aspect(location_mois)" />

									</div>
								</div>

								<div class="col">
									<div class="card m-0 p-2"
											 style="height:100%">
										Location annuelle<br />

										<b style="width:100%;text-align:right;"
											 class="text-success"
											 v-html="monetary_aspect(location_an)" />


									</div>
								</div>


								<div class="col">
									<div class="card m-0 p-2"
											 style="height:100%">
										Flux de trésorerie net <br />

										<b style="width:100%;text-align:right;"
											 class="text-success"
											 v-html="monetary_aspect(flux_reel)" />



									</div>
								</div>


							</div>


							<div class="row row-cols-2 row-cols-md-3 row-cols-xl-5 gx-lg-6 gy-6 justify-content-left">



								<div class="col">
									<div class="card m-0 p-2"
											 style="height:100%">
										Rendement locatif brut<br />

										<b style="width:100%;text-align:right;"
											 class="text-success"
											 v-html="monetary_aspect(rendement_locatif)+' %'" />


									</div>
								</div>

								<div class="col">
									<div class="card m-0 p-2"
											 style="height:100%">
										Perspective de croissance <br />

										<b style="width:100%;text-align:right;"
											 class="text-success"
											 v-html="monetary_aspect(perspective_croissance)+' %'" />



									</div>
								</div>
								<div class="col">
									<div class="card m-0 p-2"
											 style="height:100%">
										Intérêt financier <br />

										<b style="width:100%;text-align:right;"
											 class="text-success"
											 v-html="monetary_aspect(interet_mensuel*12)" />



									</div>
								</div>

								<div class="col">
									<div class="card m-0 p-2"
											 style="height:100%">
										Croissance <br />

										<b style="width:100%;text-align:right;"
											 class="text-success"
											 v-html="monetary_aspect(croissance_globale)" />



									</div>
								</div>

							</div>

							<hr style="width:100%; border:1px dotted green;"
									class="my-7" />
							<h4 class="my-6 text-red">Token </h4>
							<div class="row row-cols-2 row-cols-md-3 row-cols-xl-5 gx-lg-6 gy-6 justify-content-left">

								<div class="col">
									<div class="card m-0 p-2"
											 style="height:100%">
										Rendement Brut <br />
										<b style="width:100%;text-align:right;"
											 class="text-success"
											 v-html="monetary_aspect(rendement_brut)+' %'" />


									</div>
								</div>

								<div class="col">
									<div class="card m-0 p-2"
											 style="height:100%">
										Rendement Net <br />
										<b style="width:100%;text-align:right;"
											 class="text-success"
											 v-html="monetary_aspect(rendement_net)+' %'" />

									</div>
								</div>

								<div class="col">
									<div class="card m-0 p-2"
											 style="height:100%">
										Anticipé
										<b style="width:100%;text-align:right;"
											 v-if="style_aff=='present'"
											 class="text-success"
											 v-html="monetary_aspect(detail.anticipation)+' %'" />

										<v-text-field v-if="style_aff=='form'"
																	v-on:keyup="mise_a_jour()"
																	v-model="detail.anticipation"
																	maxlength="199"></v-text-field>
									</div>
								</div>



							</div>
							<div class="row row-cols-2 row-cols-md-3 row-cols-xl-5 gx-lg-6 gy-6 justify-content-left">



								<div class="col">
									<div class="card m-0 p-2"
											 style="height:100%">
										Date d'indexation
										<b style="width:100%;text-align:right;"
											 v-if="style_aff=='present'"
											 class="text-success"
											 v-html=" (detail.date_maj)" />


										<v-text-field v-if="style_aff=='form'"
																	v-on:keyup="mise_a_jour()"
																	v-model="detail.date_maj"
																	counter
																	maxlength="199"></v-text-field>
									</div>
								</div>




								<div class="col">
									<div class="card m-0 p-2"
											 style="height:100%">
										Valeur lors de l'indexation

										<b style="width:100%;text-align:right;"
											 v-if="style_aff=='present'"
											 class="text-success"
											 v-html="monetary_aspect(detail.valeur_maj)" />



										<v-text-field v-if="style_aff=='form'"
																	v-on:keyup="mise_a_jour()"
																	v-model="detail.valeur_maj"
																	counter
																	maxlength="199"></v-text-field>
									</div>
								</div>

								<div class="col">
									<div class="card m-0 p-2"
											 style="height:100%">
										Nombre de token

										<b style="width:100%;text-align:right;"
											 v-if="style_aff=='present'"
											 class="text-success"
											 v-html="monetary_aspect(detail.nb_token)" />


										<v-text-field v-if="style_aff=='form'"
																	v-on:keyup="mise_a_jour()"
																	v-model="detail.nb_token"
																	counter
																	maxlength="199"></v-text-field>
									</div>
								</div>
								<div class="col">

									<div class="card m-0 p-2"
											 style="height:100%">
										Cours de référence
										<b style="width:100%;text-align:right;"
											 v-if="style_aff=='present'"
											 class="text-success"
											 v-html="monetary_aspect(cours_actuel)" />



									</div>
								</div>


							</div>
							<div class="row row-cols-2 row-cols-md-3 row-cols-xl-5 gx-lg-6 gy-6 justify-content-left">


								<div class="col">
									<div class="card m-0 p-2"
											 style="height:100%">
										Code du NFT

										<b style="width:100%;text-align:right;"
											 v-if="style_aff=='present'"
											 class="text-success"
											 v-html=" (detail.nft_asset_code)" />


										<v-text-field v-if="style_aff=='form'"
																	v-model="detail.nft_asset_code"
																	counter
																	maxlength="199"></v-text-field>
									</div>
								</div>
								<div class="col">
									<div class="card m-0 p-2"
											 style="height:100%">
										Code du token

										<b style="width:100%;text-align:right;"
											 v-if="style_aff=='present'"
											 class="text-success"
											 v-html=" (detail.token_asset_code)" />


										<v-text-field v-if="style_aff=='form'"
																	v-model="detail.token_asset_code"
																	counter
																	maxlength="199"></v-text-field>
									</div>
								</div>

							</div>
							<div style="font-size:80%"
									 v-if="detail.ville != undefined">
								<br />Perspective de croissance :
								croissance annualisée sur 5 ans ({{croissance_annualise.toFixed(2)}}%) + pondération sur la dernière année ({{detail.taux_1an}}%)
								= ({{ detail.taux_1an}} + {{croissance_annualise.toFixed(2)}} x 5) /6
								: {{ ( (parseFloat(detail.taux_1an)   +  (croissance_annualise              * 5)) / 6 ) .toFixed(2) }} %
								<br />Intérêts financiers : placement mensuel des loyers sur des tokens au rendement moyen de {{detail.interetcompose}}%
								<br /> Revenu brut = rendement locatif brut ({{rendement_locatif.toFixed(2)}} %) + Taux de plus-value latente ( {{detail.plus_value_latente}} %)
								<br> Rendement locatif net = rendement brut * (1 - % de charge / 100))) = ({{rendement_locatif.toFixed(2)}} * (1 - {{detail.charge}} / 100))) : {{ (rendement_locatif  * (1 - detail.charge / 100)).toFixed(2) }}%;
								<br> Rendement financier = intérêts financiers / total du projet = ({{(interet_mensuel*12).toFixed(2)}} ) / {{total_projet}} * 100 : {{(interet_mensuel*12 / total_projet *100).toFixed(2) }}%;

								<br> Rendement net = rendement financier + Plus value latente + Rendement locatif net = {{(interet_mensuel*12 / total_projet *100).toFixed(2) }}% + {{detail.plus_value_latente}} % +
								{{ (rendement_locatif  * (1 - detail.charge / 100)).toFixed(2) }}%
								: {{( (  interet_mensuel*12 / total_projet *100)  + parseFloat(detail.plus_value_latente) +  (rendement_locatif  * (1 - parseFloat(detail.charge) / 100))  ).toFixed(2) }}% ;
							</div>


							<hr style="width:100%; border:1px dotted green;"
									class="my-7" />
							<h4 class="my-6 text-red">Souscrire </h4>







							<a style="background-color:green; color:white"
								 :href="'souscrire/'+ detail.token_asset_code"
								 v-if="detail.solde>0"
								 class="btn  ">Souscrire pour {{detail.ville}}</a>

							<a v-if="detail.solde==0"
								 class="btn btn-primary p-1 px-3">Plus de fraction disponible</a>







						</div>
					</div>
					<!--/.card-footer -->
				</div>

				<!--/column -->



			</template>





		</v-tab-item>



		<v-tab-item>

			<div class="container  ">
				<div class="alert alert-success alert-icon text-center"
						 role="alert">
					<a href="https://berlcoin.berlin/fr/invest"
						 class="alert-link hover">
						Investir à Berlin avec le Berlcoin : 11.5% en 2021 </a>
				</div>


				<feature2 accroche=""
									titre=""
									lead=""
									text=""
									:img0="'https://media.berlcoin.fr/property/'+property.path+'/map.jpg'"
									:img1="'https://media.berlcoin.fr/property/'+property.path+'/2.jpg'"
									:img2="'https://media.berlcoin.fr/property/'+property.path+'/3.jpg'"
									:img3="'https://media.berlcoin.fr/property/'+property.path+'/4.jpg'"
									:img4="'https://media.berlcoin.fr/property/'+property.path+'/5.jpg'"
									color="soft-green" />



				<div class="row gx-lg-12 gx-xl-11 gy-10 blog grid-view">
					<div class="col-lg-12">
						<article class="post">
							<div class="post-slider rounded mb-6">
								<div class="basic-slider owl-carousel dots-over"
										 data-margin="5">
									<div class="item"><img src="@/assets/img/photos/b2.jpg"
												 class=""
												 alt="" /></div>
									<div class="item"><img src="@/assets/img/photos/b3.jpg"
												 alt="" /></div>
								</div>
								<!-- /.basic-slider -->
							</div>
							<!-- /.post-slider -->
							<div class="post-header mb-5">
								<div class="post-category text-line">
									<a href="#"
										 class="hover"
										 rel="category">{{property.address}} - {{property.zipcode}} {{property.city}}</a>
								</div>
								<!-- /.post-category -->
								<h2 class="post-title mt-1 mb-4"><a class="link-dark"
										 href="@/blog-post.html">{{property.name}}</a></h2>
								<ul class="post-meta mb-0">
									<li class="post-date"><i class="uil uil-calendar-alt"></i><span>{{property.nft_date}}</span></li>
									<li class="post-author"><a href="#"><i class="uil uil-user"></i><span>SCI CONDE 52</span></a></li>

								</ul>
								<!-- /.post-meta -->
							</div>
							<!-- /.post-header -->
							<div class="post-content">
								<h4>Catégorie de bien</h4>
								<p>{{property.category}}</p>
								<h4>Transport en commun</h4>
								<p>{{property.transport}}</p>
								<h4>Quartier</h4>
								<p>{{property.description}}</p>

							</div>
							<!-- /.post-content -->
						</article>
						<!-- /.post -->
					</div>
					<!-- /column -->
				</div>
				<div class="row pb-5">
					<div class="col-lg-6 col-xl-6 col-xxl-6">

						<!-- /.row -->

						<div class="card"
								 style="height:100%">

							<div class="card-body">
								<div class="post-header">
									<div class="post-category text-line">
										<a href="#"
											 class="hover"
											 rel="category">Acquisition</a>
									</div>
									<!-- /.post-category -->
								</div>
								<!-- /.post-header -->
								<div class="post-content">

									<table class="table table-bordered">
										<tr>
											<td> Surface </td>
											<td> </td>
											<td style="text-align:right; padding-right:10px;"> {{property.surface}} m2 </td>
										</tr>
										<tr>
											<td> prix m2 </td>
											<td> </td>
											<td style="text-align:right; padding-right:10px;"> {{prix_m2}} </td>
										</tr>
										<tr>
											<td> prix acquisition </td>
											<td style="text-align:right; padding-right:10px;"> </td>
											<td style="text-align:right; padding-right:10px;"> {{prix_aquisition}} </td>
										</tr>

									</table>
								</div>
								<!-- /.post-content -->
							</div>
							<!--/.card-body -->
							<div class="card-footer">
								<ul class="post-meta d-flex mb-0">
									<li class="post-date"><b>Montant total d'acquisition</b></li>

									<li class="post-likes ms-auto"> <b class="h1 text-success">{{ prix_acquisition_total }} </b> </li>
								</ul>
								<!-- /.post-meta -->
							</div>
							<!-- /.card-footer -->
						</div>

						<!-- /.position-relative -->
					</div>


					<div class="col-lg-6 col-xl-6 col-xxl-6">

						<div class="card"
								 style="height:100%">
							<div class="card-body">
								<div class="post-header">
									<div class="post-category text-line">
										<a href="#"
											 class="hover"
											 rel="category">Financement</a>
									</div>
									<!-- /.post-category -->
								</div>
								<!-- /.post-header -->
								<div class="post-content">
									<table class="table table-bordered">
										<tr>
											<td> Apport direct</td>
											<td style="text-align:right; padding-right:10px;"> </td>
											<td style="text-align:right; padding-right:10px;"> {{rep_apport_direct}} </td>
										</tr>
										<tr>
											<td> Banque </td>
											<td style="text-align:right; padding-right:10px;"> </td>
											<td style="text-align:right; padding-right:10px;"> {{part_banque}} </td>
										</tr>
										<tr>
											<td> Provision </td>
											<td style="text-align:right; padding-right:10px;"> </td>
											<td style="text-align:right; padding-right:10px;"> {{prix_provision}} </td>
										</tr>

									</table>
								</div>
								<!-- /.post-content -->
							</div>
							<!--/.card-body -->
							<div class="card-footer">
								<ul class="post-meta d-flex mb-0">
									<li class="post-date"><b>Montant total du financement</b></li>

									<li class="post-likes ms-auto"> <b class="h1 text-success">{{ financement }} </b> </li>
								</ul>
								<!-- /.post-meta -->
							</div>
							<!-- /.card-footer -->
						</div>
						<!-- /.card -->

						<!-- /.position-relative -->
					</div>
				</div>

				<div class="row mb-5">
					<div class="col-lg-6 col-xl-6 col-xxl-6">

						<!-- /.row -->

						<div class="card  "
								 style="height:100%">

							<div class="card-body">
								<div class="post-header">
									<div class="post-category text-line">
										<a href="#"
											 class="hover"
											 rel="category">Provision et équipement sur 10 ans</a>
									</div>
									<!-- /.post-category -->
								</div>
								<!-- /.post-header -->
								<div class="post-content">

									<table class="table table-bordered">
										<tr>
											<td> Mobilier</td>
											<td> </td>
											<td style="text-align:right; padding-right:10px;"> {{mobilier}} </td>
										</tr>
										<tr>
											<td> Coût de possession</td>
											<td style="text-align:right; padding-right:10px;"> {{taux_possession}} %</td>
											<td style="text-align:right; padding-right:10px;"> {{prix_possession}} </td>
										</tr>
										<tr>
											<td> Impôts locaux </td>
											<td style="text-align:right; padding-right:10px;"> </td>
											<td style="text-align:right; padding-right:10px;"> {{imposition}} </td>
										</tr>
										<tr>
											<td> Remis en etat </td>
											<td style="text-align:right; padding-right:10px;"> {{remise_etat}} / m2</td>
											<td style="text-align:right; padding-right:10px;"> {{prix_remise_etat }} </td>
										</tr>

									</table>
								</div>
								<!-- /.post-content -->
							</div>
							<!--/.card-body -->
							<div class="card-footer">
								<ul class="post-meta d-flex mb-0">
									<li class="post-date"><b>Montant total des provisions</b></li>

									<li class="post-likes ms-auto"> <b class="h1 text-success">{{ prix_provision }} </b> </li>
								</ul>
								<!-- /.post-meta -->
							</div>
							<!-- /.card-footer -->
						</div>
						<!-- /.card -->

						<!-- /.position-relative -->
					</div>



					<div class="col-lg-6 col-xl-6 col-xxl-6">

						<div class="card "
								 style="height:100%">
							<div class="card-body">
								<div class="post-header">
									<div class="post-category text-line">
										<a href="#"
											 class="hover"
											 rel="category">Berlcoins</a>
									</div>
									<!-- /.post-category -->
								</div>
								<!-- /.post-header -->
								<div class="post-content">
									<table class="table table-bordered">
										<tr>
											<td> Valeur du Berlcoin lors de l'émission</td>
											<td style="text-align:right; padding-right:10px;"> </td>
											<td style="text-align:right; padding-right:10px;"> {{berlcoin_price}} </td>
										</tr>
										<tr>
											<td> Berlcoins distribués investisseur</td>
											<td style="text-align:right; padding-right:10px;"> </td>
											<td style="text-align:right; padding-right:10px;"> {{investisseur}} Blc</td>
										</tr>


										<tr>
											<td> Berlcoins distribués prescipteur</td>
											<td style="text-align:right; padding-right:10px;"> </td>
											<td style="text-align:right; padding-right:10px;"> {{prescripteur}} Blc</td>
										</tr>

										<tr>
											<td> Berlcoins non distribués </td>
											<td style="text-align:right; padding-right:10px;"> </td>
											<td style="text-align:right; padding-right:10px;"> {{nondistribue}} Blc</td>
										</tr>

									</table>
								</div>
								<!-- /.post-content -->
							</div>
							<div class="card-footer">
								<ul class="post-meta d-flex mb-0">
									<li class="post-date"><b>Berlcoins</b></li>

									<li class="post-likes ms-auto"> <b class="h1 text-success">{{ berlcoins + prescripteur +  nondistribue -1}} Blc </b> </li>
								</ul>
								<!-- /.post-meta -->
							</div>
						</div>
						<!-- /.card -->

					</div>

				</div>
				<div class="row pb-5">



					<div class="col-lg-6 col-xl-6 col-xxl-6 mb-5">
						<div class="card "
								 style="height:100%">
							<div class="card-body">
								Taux de croissance de la garantie <b class="h1 text-success"
									 style="float:right;"> {{rendement +croissance }} %</b>
							</div>
						</div>
					</div>


					<div class="col-lg-6 col-xl-6 col-xxl-6  mb-5">
						<div class="card "
								 style="height:100%">
							<div class="card-body">
								Croissance mensuelle<b class="h1 text-success"
									 style="float:right;"> {{augmentation_mensuelle}} </b>
							</div>
						</div>
					</div>


				</div>
			</div>
			<!-- /section -->
			<div class="alert alert-success alert-icon text-center"
					 role="alert">
				<a href="https://berlcoin.berlin/fr/invest"
					 class="alert-link hover">
					Investir à Berlin avec le Berlcoin : 11.5% en 2021 </a>
			</div>


		</v-tab-item>
	</v-tabs>
</v-card>
</template>

<script>
const eurocurrency = new Intl.NumberFormat('fr-FR', {
	style: 'currency',
	currency: 'EUR',
	minimumFractionDigits: 2
});


import feature2 from "@/components/feature2.vue";

export default {
	name: 'property',
	components: {
		feature2
	},
	props: {},
	data: () => ({

		tab: null,
		table_id: "",
		save_return: [],
		headers: [{
				text: 'id ',
				align: 'start',
				value: 'id',
			},
			{
				text: 'Adresse',
				value: 'adresse',
				align: 'start',
			},
			{
				text: 'CP',
				value: 'code_postal',
				align: 'start',
			},
			{
				text: 'Ville',
				value: 'ville',
				align: 'start',
			}

			,
			{
				text: 'Montant',
				value: 'total',
				align: 'start',
			},
			{
				text: 'Token',
				value: 'nb_token',
				align: 'start',
			}

		],

		headers_berlcoin: [{
				text: 'id ',
				align: 'start',
				value: 'id',
			},
			{
				text: 'Adresse',
				value: 'name',
				align: 'start',
			},
			{
				text: 'CP',
				value: 'zipcode',
				align: 'start',
			},
			{
				text: 'Ville',
				value: 'city',
				align: 'start',
			}

			,
			{
				text: 'Montant',
				value: 'euros',
				align: 'start',
			},
			{
				text: 'Token',
				value: 'berlcoin',
				align: 'start',
			}

		],

		detail: [],
		property: [],
		axios_private: [],
		data_list: [],
		search: '',
		table_id: '',
		berlcoin_id: '',
		style_aff: "present",

		move_tab: true,
		tab: null,

		croissance_annualise: undefined,
		total_projet: undefined,
		location_mois: undefined,
		location_an: undefined,
		rendement_locatif: undefined,
		perspective_croissance: undefined,
		rendement_brut: undefined,
		rendement_net: undefined,
		cours_actuel: undefined,
		flux_reel: undefined,
		interet_mensuel: undefined,
		croissance_globale: undefined,

		berlcoin_property: [],

		courtage: undefined,
		mutation: undefined,
		notaire: undefined,
		registre: undefined,
		dossier: undefined,
		loyer: undefined,
		charge: undefined,
		surface: undefined,
		prix_m2: undefined,
		berlcoin_price: undefined,
		prix_aquisition: undefined,
		prix_courtage: undefined,
		prix_mutation: undefined,
		prix_notaire: undefined,
		prix_registre: undefined,
		prix_acquisition_total: undefined,

		mobilier: undefined,
		taux_possession: undefined,
		remise_etat: undefined,

		prix_possession: undefined,
		prix_remise_etat: undefined,
		imposition: undefined,
		prix_provision: undefined,
		taux_emprunt: undefined,
		part_banque: undefined,
		rep_apport_direct: undefined,
		rep_banque: undefined,
		rep_provision: undefined,
		financement: undefined,
		annualite: undefined,
		taux_interet: undefined,
		duree_emprunt: undefined,
		croissance_loyer: undefined,
		loyer10ans: undefined,
		rendement: undefined,
		berlcoins: undefined,


		investisseur: undefined,
		prescripteur: undefined,
		nondistribue: undefined,
		croissance: undefined,
		augmentation: undefined,
		augmentation_mensuelle: undefined,
		tokenshow: undefined,
		clepublique: '',

		montant: 10
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {
		this.tokenshow = this.$route.params.id;
		//		console.log(this.tokenshow);
		this.init()
	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {
		email() {
			return this.$store.state.email
		},
		token() {
			return this.$store.state.session_token
		},
		routeparam() {
			this.$route.params;
		}
	},
	methods: {
		souscrire() {
			this.opt = [];
			this.opt["api"] = "write_contract"
			this.opt["debug"] = false
			this.opt["cle"] = this.clepublique
			this.opt["montant"] = this.montant
			this.opt["token_asset_code"] = this.detail.token_asset_code
			this.charge_private();
		},

		init() {
			this.opt = [];
			this.opt["api"] = "list_property"
			this.opt["debug"] = false
			this.opt["email"] = this.email
			this.opt["token"] = this.token
			this.charge_private();

			this.opt = [];
			this.opt["debug"] = false
			this.opt["api"] = "property"

			this.opt["selection"] = "berlcoin"
			this.charge_private();


		},
		save_table_property() {
			this.detail.total = this.total_projet;
			this.opt = this.detail;
			this.opt["api"] = "save_property"
			this.opt["debug"] = false
			this.opt["id"] = this.table_id


			this.$store.dispatch('post', this.opt).then(response => {
				if (response != undefined) this.save_return = response.data
			})
		},
		mise_a_jour() {

			this.croissance_annualise = (Math.pow(1 + (parseFloat(this.detail.taux_5an) / 100), 1 / 5) - 1) * 100;
			this.total_projet = parseFloat(this.detail.prix_achat) + parseFloat(this.detail.travaux) + parseFloat(this.detail.mobilier) + parseFloat(this.detail.reserve)
			this.location_mois = parseFloat(this.detail.nb_chambre) * parseFloat(this.detail.location_chambre);
			this.location_an = 12 * this.location_mois;
			this.rendement_locatif = this.location_an / this.total_projet * 100;
			this.perspective_croissance = (((((Math.pow(1 + (parseFloat(this.detail.taux_5an) / 100), 1 / 5) - 1) * 100) * 5) + parseFloat(this.detail.taux_1an)) / 6);
			this.rendement_brut = parseFloat(this.detail.plus_value_latente) + this.rendement_locatif;
			this.flux_reel = this.location_mois * (1 - parseFloat(this.detail.charge) / 100)
			let temp_inc = (Math.pow((1 + parseFloat(this.detail.interetcompose) / 100), 1 / 12) - 1) * 100;
			let mois = [];
			mois[0] = 0;

			mois[1] = this.flux_reel * temp_inc / 100;
			mois[2] = (this.flux_reel + mois[1]) * temp_inc / 100;
			mois[3] = (this.flux_reel + mois[1] + mois[2]) * temp_inc / 100;
			mois[4] = (this.flux_reel + mois[1] + mois[2] + mois[3]) * temp_inc / 100;
			mois[5] = (this.flux_reel + mois[1] + mois[2] + mois[3] + mois[4]) * temp_inc / 100;
			mois[6] = (this.flux_reel + mois[1] + mois[2] + mois[3] + mois[4] + mois[5]) * temp_inc / 100;
			mois[7] = (this.flux_reel + mois[1] + mois[2] + mois[3] + mois[4] + mois[5] + mois[6]) * temp_inc / 100;
			mois[8] = (this.flux_reel + mois[1] + mois[2] + mois[3] + mois[4] + mois[5] + mois[6] + mois[7]) * temp_inc / 100;
			mois[9] = (this.flux_reel + mois[1] + mois[2] + mois[3] + mois[4] + mois[5] + mois[6] + mois[7] + mois[8]) * temp_inc / 100;
			mois[10] = (this.flux_reel + mois[1] + mois[2] + mois[3] + mois[4] + mois[5] + mois[6] + mois[7] + mois[8] + mois[9]) * temp_inc / 100;
			mois[11] = (this.flux_reel + mois[1] + mois[2] + mois[3] + mois[4] + mois[5] + mois[6] + mois[7] + mois[8] + mois[9] + mois[10]) * temp_inc / 100;

			let somme = 0;
			let total = 0;

			for (let i = 1; i < 12; i++) {
				somme = somme + mois[i];
				total = total + somme;
			}


			this.interet_mensuel = total / 12;

			let rendement_financier = (this.interet_mensuel * 12) / this.total_projet * 100;

			this.rendement_net = rendement_financier + parseFloat(this.detail.plus_value_latente) + ((this.rendement_locatif) * (1 - this.detail.charge / 100));
			//this.croissance_globale =  (this.total_projet * (1+this.perspective_croissance)/100)  + (this.flux_reel *12) +  (this.interet_mensuel *12 )
			this.croissance_globale = this.total_projet * (1 + this.rendement_net / 100) - this.total_projet;
			let date1 = new Date(this.detail.date_maj);
			let date2 = new Date();
			let Diff_temps = date2.getTime() - date1.getTime();
			let Diff_jours = Diff_temps / (1000 * 3600 * 24);


			this.cours_actuel = parseFloat(this.detail.valeur_maj) + (Diff_jours * (parseFloat(this.detail.valeur_maj) * parseFloat(this.detail.anticipation) / 100) / 365);


		},
		charge_private() {
			this.$store.dispatch('post', this.opt).then(response => {
				if (response != undefined) this.axios_private = response.data
			})
		},
	},
	watch: {
		routeparam: function () {
			console.log(this.routeparam)
		},
		berlcoin_id: function () {
			if (this.move_tab) this.tab = 2;


			for (let index = 0; index < this.berlcoin_property.length; index++)
				if (this.berlcoin_id == this.berlcoin_property[index]["id"])
					this.property = this.berlcoin_property[index];
			this.courtage = this.property.courtage
			this.mutation = this.property.mutation
			this.notaire = this.property.notaire
			this.registre = this.property.registre
			this.dossier = this.property.dossier
			this.loyer = this.property.loyer
			this.charge = this.property.charge
			this.croissance = parseFloat(this.property.capital_gain);

			this.duree_emprunt = this.property.duree_emprunt
			this.surface = parseFloat(this.property.surface);
			this.prix_m2 = parseFloat(this.property.prix_m2);
			this.croissance_loyer = parseFloat(this.property.croissance_loyer);
			this.berlcoin_price = parseFloat(this.property.berlcoin_price);
			this.prix_aquisition = this.prix_m2 * this.surface;
			this.prix_courtage = this.prix_aquisition * this.courtage / 100;
			this.prix_mutation = this.prix_aquisition * this.mutation / 100;

			this.prix_notaire = this.prix_aquisition * this.notaire / 100;
			this.prix_registre = this.prix_aquisition * this.registre / 100;

			this.prix_acquisition_total = this.prix_registre + this.prix_notaire + this.prix_mutation + this.prix_courtage + this.prix_aquisition;

			this.mobilier = parseFloat(this.property.mobilier);
			this.taux_possession = parseFloat(this.property.taux_possession);
			this.remise_etat = parseFloat(this.property.remise_etat);

			this.prix_possession = parseFloat(this.taux_possession * this.prix_aquisition / 100);
			this.prix_remise_etat = parseFloat(this.remise_etat * this.surface);
			this.imposition = parseFloat(this.property.imposition);
			this.taux_emprunt = parseFloat(this.property.taux_emprunt);
			this.dossier = parseFloat(this.property.dossier);

			this.prix_provision = this.imposition + this.mobilier + this.prix_remise_etat + this.prix_possession;

			this.part_banque = parseFloat(this.taux_emprunt * this.prix_aquisition / 100);


			this.rep_apport_direct = this.prix_acquisition_total + this.dossier - this.part_banque;
			this.financement = this.rep_apport_direct + this.prix_provision + this.part_banque;
			this.taux_interet = this.property.taux_interet;
			this.duree_emprunt = 20;
			this.annualite = this.part_banque * (this.taux_interet / (1 - (1 + this.taux_interet) ^ -this.duree_emprunt));
			this.berlcoins = Math.ceil((this.financement / this.berlcoin_price) * 1) / 1;

			//this.investisseur = Math.floor((this.rep_apport_direct / this.berlcoin_price) * 1) / 1;
			//this.prescripteur = Math.floor((this.investisseur * 0.03) * 1) / 1;
			//		this.nondistribue = Math.floor((this.berlcoins - this.prescripteur - this.investisseur) * 1) / 1;
			this.investisseur = (Math.floor((this.berlcoins) * 1) / 1) - 1;
			this.prescripteur = Math.floor((this.berlcoins * 0.03) * 1) / 1;
			this.nondistribue = Math.floor((this.berlcoins * 0.07) * 1) / 1;
			this.nondistribue = 0;
			this.loyer10ans = (parseFloat(this.loyer) + parseFloat(this.charge)) * 120;
			this.rendement = Math.floor(this.loyer10ans / (this.prix_acquisition_total) * 1000) / 100
			// this.loyer10ans = ( this.loyer + this.charge  ) * (((1+(this.croissance_loyer/12)  ) ^ 120)-1 ) / (this.croissance_loyer/12) ;

			let K59 = parseFloat(this.loyer) + parseFloat(this.charge);
			let K62 = parseFloat(this.croissance_loyer) / 100 / 12
			let K63 = 120

			let K65 = 1 + K62;
			let K66 = (K65 ^ K63) / 100;
			let K67 = K66 - 1;
			let K68 = K59 * K67;
			let K69 = K68 / K62;



			//    this.loyer10ans = K59 * ((((1 + K62) ^ K63) / 100) - 1) / K62
			//	console.log("loyer : " + this.loyer)
			//	console.log("charge : " + this.charge)
			//	console.log("loyer10ans : " + this.loyer10ans)

			/*
                K65 	1,001666667  1.0016666666666667
               K66	1,221199434
               K67	0,2211994339
               K68	165,8995754
               K69	99539,74524

        */


			this.augmentation = (this.prix_aquisition * ((this.croissance + this.rendement) / 100)) / 365;
			this.augmentation_mensuelle = this.augmentation * 365 / 12;

			this.loyer = parseFloat(this.loyer) * 120;
			this.charge = parseFloat(this.charge) * 120;

			this.prix_m2 = eurocurrency.format(parseFloat(this.prix_m2));
			this.prix_aquisition = eurocurrency.format(parseFloat(this.prix_aquisition));
			this.prix_courtage = eurocurrency.format(parseFloat(this.prix_courtage));
			this.prix_mutation = eurocurrency.format(parseFloat(this.prix_mutation));
			this.prix_notaire = eurocurrency.format(parseFloat(this.prix_notaire));
			this.prix_registre = eurocurrency.format(parseFloat(this.prix_registre));
			this.prix_acquisition_total = eurocurrency.format(parseFloat(this.prix_acquisition_total));
			this.mobilier = eurocurrency.format(parseFloat(this.mobilier));
			this.prix_possession = eurocurrency.format(parseFloat(this.prix_possession));
			this.imposition = eurocurrency.format(parseFloat(this.imposition));
			this.prix_remise_etat = eurocurrency.format(parseFloat(this.prix_remise_etat));

			this.remise_etat = eurocurrency.format(parseFloat(this.remise_etat));
			this.prix_provision = eurocurrency.format(parseFloat(this.prix_provision));
			this.part_banque = eurocurrency.format(parseFloat(this.part_banque));
			this.dossier = eurocurrency.format(parseFloat(this.dossier));
			this.rep_apport_direct = eurocurrency.format(parseFloat(this.rep_apport_direct));

			this.financement = eurocurrency.format(parseFloat(this.financement));
			this.annualite = eurocurrency.format(parseFloat(this.annualite));
			this.loyer10ans = eurocurrency.format(parseFloat(this.loyer10ans));
			this.loyer = eurocurrency.format(parseFloat(this.loyer));
			this.charge = eurocurrency.format(parseFloat(this.charge));
			this.berlcoin_price = eurocurrency.format(parseFloat(this.berlcoin_price));
			this.augmentation = eurocurrency.format(parseFloat(this.augmentation));
			this.augmentation_mensuelle = eurocurrency.format(parseFloat(this.augmentation_mensuelle));


		},
		table_id: function () {

			if (this.move_tab) this.tab = 1;

			if (this.data_list.length > 0) {
				if (this.table_id == 0) {
					let temp = {};
					temp.id = 0;
					for (var key in this.detail)
						if (key != 'id') temp[key] = "";
					this.data_list.push(temp);
				}
				for (let index = 0; index < this.data_list.length; index++)
					if (this.table_id == this.data_list[index]["id"])
						this.detail = this.data_list[index];
				this.mise_a_jour();
				this.move_tab = true;
			}
		},
		save_return: function () {
			if (this.save_return != undefined)
				if (this.save_return.result = true) {
					this.tab = 0;
					this.move_tab = false;
					this.init();
				}
		},
		axios_private: function () {
			//	console.log(this.axios_private)
			if (this.axios_private != undefined) {

				if (this.axios_private.result == "berlcoin_property_list") {
					this.berlcoin_property = this.axios_private.data;
					if (this.berlcoin_property.length > 0) {
						this.move_tab = false;
						this.property = this.axios_private.data[0];
						this.berlcoin_id = this.axios_private.data[0]["id"];
					}
				}
				if (this.axios_private.result == "france") {
					this.data_list = this.axios_private.data;
					if (this.data_list.length > 0) {
						this.move_tab = false;
						this.table_id = this.data_list[0]["id"];
						this.detail = this.data_list[0];
						if (this.tokenshow != undefined) {
							for (let i = 0; i < this.data_list.length; i++) {
								if (this.tokenshow == this.data_list[i]["token_asset_code"]) {
									this.table_id = this.data_list[i]["id"];
									this.detail = this.data_list[i];
									this.tab = 1;
								}
							}
						}
					}
				}
			}
		}
	}
}
</script>



<style>
html,
body {
	font-family: 'Montserrat', sans-serif;
}

#app {
	font-family: Montserrat, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
}

#nav {
	padding: 30px;
}

#nav a {
	font-weight: bold;
	color: #2c3e50;
}

#nav a.router-link-exact-active {
	color: #42b983;
}
</style>
